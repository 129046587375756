import * as React from 'react'
import Layout from '../components/Layout'
import LinkBar from '../components/LinkBar'

import {Helmet} from "react-helmet"
import {StaticImage} from "gatsby-plugin-image"
import {Link} from 'gatsby'

//Modular stylesheet

//Global stylesheet
import "../components/styles/global.css"


const copytemplate = () => {
    return(
        <Layout pageTitle={"copytemplate"}>
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>

                <title>Explore Bangbiangyang | Ministry of Tourism Official Website</title>

                <meta name="author" content="aqq.xyz"/>
                <meta name="description" content="EDIT THIS"/>

                <meta property="og:title" content="Explore Bangbiangyang | Ministry of Tourism Official Website"/>
                <meta property="og:description" content="EDIT THIS"/>
                <meta property="og:url" content="bangbiangyang.aqq.xyz/copytemplate"/>
                <meta property="og:site_name" content="bangbiangyang.aqq.xyz"/>

                <meta name="robots" content="noindex"/>

                <link rel="icon" href="../static/favicon.ico" />
            </Helmet>

            <LinkBar>
                <Link to="/">Home</Link> > <Link to="copytemplate">copytemplate</Link>
            </LinkBar>

            <div className="midContentWrapper">
                Insert stuff.
            </div>

        </Layout>
    )
}

export default copytemplate